<template>
  <el-button :size="size" :type="type" v-if="isPerm" @click="handleClick">
    <slot />
  </el-button>
</template>
<script>
import hasPerm from "@/utils/hasPermSign";
import Vue from "vue";
import { Button } from "element-ui";
import { mapState } from "vuex";
Vue.use(Button);
export default {
  props: {
    perm: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  computed: {
    ...mapState(["permSign"]),
  },
  data() {
    return {
      isPerm: false,
    };
  },
  mounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
    this.$bus.$on("onPermSignChanged", this.onPermSignChanged);
  },
  unmounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  destroyed() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  watch: {
    //子组件props首次获取到父组件传来的值时，需要执行函数，此时就需要将immediate设为true
    perm: {
      handler: function(val) {
        //判断是否有权限
        this.isPerm = this.hasPerm(val);
      },
      immediate: true,
    },
    "$store.state.permSign": {
      handler: function(val) {
        this.isPerm = this.hasPerm(this.perm);
      },
      immediate: true,
    },
    permSign: {
      handler: function(val) {
        this.isPerm = this.hasPerm(this.perm);
      },
      immediate: true,
    },
  },
  methods: {
    handleClick: function() {
      // 按钮操作处理函数
      this.$emit("click");
    },
    hasPerm,
    onPermSignChanged() {
      this.isPerm = this.hasPerm(this.perm);
    },
  },
};
</script>
