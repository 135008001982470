<template>
  <!-- v-if="importStatus > -1" -->
  <el-form-item>
    <el-popover placement="bottom" width="400" trigger="click">
      <el-upload
        class="upload-demo"
        ref="upload"
        :multiple="false"
        action="#"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :http-request="httpUploadRequest"
        :auto-upload="true"
        v-loading="loading"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <!--            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
      </el-upload>
      <el-button
        v-loading="loading"
        size="small"
        type="warning"
        slot="reference"
        @click.native.stop=""
        :disabled="importStatus == 1"
        >{{ importStatus == 1 ? "导入中" : "导入数据" }}</el-button
      >
    </el-popover>
  </el-form-item>
</template>

<script>
export default {
  name: "importButton",
  components: {},
  props: {
    importUrl: {
      type: String,
    },
    statusUrl: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      new_query: [],
      loading: false,
      importStatus: -1,
      interval: -1,
      checkingStatus: false,
    };
  },
  methods: {
    async reloadImportStatus() {
      this.checkingStatus = true;
      const { data, errorCode } = await this.$http.getByUrl(
        this.statusUrl,
        this.new_query
      );
      this.checkingStatus = false;
      if (errorCode != "0000") return;
      this.importStatus = data.status;
      if (this.importStatus == 1) {
        this.startCheckStatus();
      }
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let { errorCode } = await this.$http.uploadFileToServer(
        this.importUrl,
        item.file,
        this.data
      );
      this.importStatus = 1;
      await this.reloadImportStatus();
      if (errorCode == "0000") {
        this.$message.success("上传开始，请等待结果");
        this.startCheckStatus();
      }

      this.loading = false;
    },
    startCheckStatus() {
      if (this.interval != -1) {
        window.clearInterval(this.interval);
      }

      this.interval = window.setInterval(async () => {
        if (this.checkingStatus) {
          return;
        }

        await this.reloadImportStatus();
        if (this.importStatus == 0) {
          this.$message.success("导入完成，请前往导入历史查看结果");
          //查询列表
          this.$emit("refushList");
          if (this.interval != -1) {
            window.clearInterval(this.interval);
          }
        }
      }, 1000);
    },
  },
  created() {
    if (this.interval != -1) {
      window.clearInterval(this.interval);
    }
    console.log("dttrrty--", this.data);
    if (
      JSON.stringify(this.data) != "{}" &&
      JSON.stringify(this.data) != undefined
    ) {
      this.new_query = this.data;
    }
    // this.reloadImportStatus();
  },
  destroyed() {
    if (this.interval != -1) {
      window.clearInterval(this.interval);
    }
  },
};
</script>

<style scoped></style>
